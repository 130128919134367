import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Typography } from '@material-ui/core';

const defaultSettings = {
  arrows: true,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

interface ISlickSlider {
  settings?: object;
  children: React.ReactNode;
  classesnames?: any;
}

const isBrowser = typeof window !== 'undefined';

const SlickSlider = ({ settings, children, classesnames }: ISlickSlider) => {
  const currentSettings = settings
    ? Object.assign(defaultSettings, settings)
    : defaultSettings;

  if (isBrowser) {
    return (
      <Slider {...currentSettings} className={classesnames}>
        {children}
      </Slider>
    );
  }
  return <Typography>Slick does not supported</Typography>;
};

export default SlickSlider;
